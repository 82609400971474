import io from 'socket.io-client';

let socket;

const API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const initializeSocket = (token) => {
  socket = io(API_BASE_URL, {
    auth: {
      token
    }
  });

  socket.on('connect', () => {
    console.log('Socket connected');
  });

  socket.on('disconnect', () => {
    console.log('Socket disconnected');
  });

  return socket;
};

export const getSocket = () => {
  if (!socket) {
    throw new Error('Socket not initialized. Call initializeSocket first.');
  }
  return socket;
};

export const joinChat = (chatId) => {
  const socket = getSocket();
  socket.emit('join chat', chatId);
};

export const sendMessage = (chatId, message) => {
  const socket = getSocket();
  socket.emit('chat message',  chatId, message );
};

export const sendImage = (chatId, imageData) => {
  const socket = getSocket();
  socket.emit('chat image', chatId, imageData);
};